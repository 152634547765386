import React from "react";
import { graphql } from "gatsby";
import Layout from "../layouts";
import SEO from "../components/seo";
import PropTypes from "prop-types";
import BlockContent from "@sanity/block-content-to-react";
import serializers from "../helpers/text-serializer";
import PageBuilder from "../modules/page-builder";
import Header from "../modules/header";
import EventDetails from "../components/event-details";
import Related from "../modules/related";

export const query = graphql`
  query EventTemplateQuery($id: String!) {
    event: sanityEvent(id: { eq: $id }) {
      title
      excerpt
      slug {
        current
      }
      heroText
      ...eventBuilderFields
      image {
        asset {
          _id
          altText
          metadata {
            lqip
            dimensions {
              width
              height
              aspectRatio
            }
          }
        }
        hotspot {
          height
          width
          x
          y
        }
      }
      seo {
        title
        description
        keywords
        image {
          asset {
            _id
            altText
          }
        }
      }
      isFuture
      eventType
      location
      dateStart
      dateEnd
      _rawBody(resolveReferences: { maxDepth: 5 })
      registrationLink
      registrationButtonText
      registrationOpen
      sidebarText {
        heading
        text
      }
      related {
        ... on SanityArticle {
          title
          path
          _type
          articleType
          taxonomy {
            topic {
              title
            }
          }
        }
        ... on SanityPolicySubmission {
          title
          path
          _type
          articleType
          taxonomy {
            topic {
              title
            }
          }
        }
        ... on SanityEvent {
          _type
          title
          dateEnd
          dateStart
          eventType
          location
          path
        }
        ... on SanityPage {
          _type
          title
          excerpt
          path
        }
      }
      breadcrumbs {
        title
        slug
      }
      taxonomy {
        topic {
          title
          parentTopic
        }
        tags {
          title
        }
      }
    }
  }
`;

const EventTemplate = props => {
  const { data } = props;
  const event = data.event;

  return (
    <Layout className="article">
      <SEO
        title={event.title}
        excerpt={event.excerpt}
        image={event.image}
        seo={event.seo}
        taxonomy={event.taxonomy}
      />
      <Header
        title={event.title}
        text={event.heroText}
        date={new Date(event.dateStart)}
        dateEnd={new Date(event.dateEnd)}
        image={event.image}
        breadcrumbs={event.breadcrumbs}
        backgroundColour={"beige"}
      />
      <section className="m-article-body container body-grid">
        <div className="m-article-body-main">
          {event?._rawBody?.[0] && (
            <BlockContent
              className="m-article-body-content"
              blocks={event._rawBody}
              serializers={{
                serializers,
                types: {
                  block: serializers.types.block,
                  undefined: serializers.undefined,
                  blockVideo: serializers.blockVideo,
                  blockFigure: serializers.blockFigure,
                  pdfButton: serializers.pdfButton,
                },
                marks: {
                  link: serializers.marks.link,
                },
              }}
              renderContainerOnSingleChild={true}
            />
          )}
        </div>
        <div className="m-article-body-sidebar">
          {(event.registrationLink || event.sidebarText?.[0]) && (
            <EventDetails
              registrationLink={event.registrationLink}
              registrationOpen={event.registrationOpen ? true : false}
              registrationText={event.registrationButtonText || "Register"}
              sidebarText={event.sidebarText}
            />
          )}
        </div>
      </section>
      <PageBuilder builder={event.pageBuilder} />
      {event.related?.[0] && <Related related={event.related} />}
    </Layout>
  );
};

EventTemplate.propTypes = {
  data: PropTypes.object,
};

export default EventTemplate;
