import React from "react";
import PropTypes from "prop-types";
import Button from "../elements/button";

const EventDetails = ({
  registrationLink,
  registrationOpen,
  sidebarText,
  registrationText = "Register",
}) =>
  (registrationLink || sidebarText?.[0]) && (
    <div className="c-event-details">
      <div className="c-event-details-inner">
        {registrationLink && (
          <Button
            linkType="external"
            link={registrationLink}
            includeIcon={true}
            disabled={registrationOpen ? false : true}
            type="primary"
            text={registrationOpen ? registrationText : "Closed"}
          />
        )}
        {sidebarText?.[0] && (
          <dl className="c-event-details-copy">
            {sidebarText.map((item, i) => (
              <div className="c-event-details-item" key={i}>
                {item.heading && (
                  <dt className="c-event-details-heading metadata">
                    {item.heading}
                  </dt>
                )}
                {item.text && (
                  <dd className="c-event-details-value body-regular">
                    {item.text}
                  </dd>
                )}
              </div>
            ))}
          </dl>
        )}
      </div>
    </div>
  );

EventDetails.propTypes = {
  registrationLink: PropTypes.string,
  registrationText: PropTypes.string,
  registrationOpen: PropTypes.bool,
  sidebarText: PropTypes.arrayOf(
    PropTypes.shape({ title: PropTypes.string, text: PropTypes.string })
  ),
};

export default EventDetails;
